body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 8px;
  z-index: -99999;
  padding: 10px;
}

::-webkit-scrollbar-track {
  visibility: hidden;
}

::-webkit-scrollbar-thumb {
  background-color: #922fda;
  border-radius: 4px;
  padding: 10px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
